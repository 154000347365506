import { cn, parseMessage } from '@components/helper'
import Code from '@components/product/Code'
import { Button, Picture, PopLayout, Text } from '@components/ui'
import { XMarkIcon } from '@heroicons/react/24/outline'
import fetcher from '@lib/fetcher'
import { getAdCookie } from '@lib/utils/cookies'
import { I18N_STORE_DOMAIN } from '@shopify/const'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import Policy from '../Policy/Policy'
import { pageGTMEvent } from '@lib/utils/thirdparty'
import { sha256 } from '@lib/utils/tools'
import { useRelativeLink } from '@commerce/product/use-relative-link'

const NewCustomersPop = ({
  data,
  pageType,
  hasScroll,
  isVisible,
  setIsVisible,
}) => {
  const [open, setOpen] = useState(false)
  const [fixedOpen, setFixedOpen] = useState(true)
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState(false)
  const [code, setCode] = useState(false)
  const [codeData, setCodeData] = useState({})
  const [error, setError] = useState(false)
  const { locale } = useRouter()
  const [showMemberGift, setShowMemberGift] = useState(false)
  const [showCodeBox, setShowCodeBox] = useState(false)

  useEffect(() => {
    if (!hasScroll) {
      window.addEventListener('scroll', () => {
        // 超过两屏弹出弹窗
        if (
          window.scrollY > window.innerHeight &&
          !Cookies.get('NewCustomersPop')
        ) {
          // console.log('Cookies', Cookies.get('NewCustomersPop'))
          setOpen(true)
        }
      })
    }
  }, [])

  useEffect(() => {
    if (open || isVisible) {
      pageGTMEvent({
        event: 'uaEvent',
        eventCategory: 'newuser_sub_pop',
        eventAction: '',
        eventLabel: 'impression',
        nonInteraction: true,
      })
      pageGTMEvent({
        event: 'ga4Event',
        event_name: 'lp_button',
        event_parameters: {
          page_group: 'newer_sub_pop',
          position: '',
          button_name: 'impression',
        },
      })
    }
  }, [open])

  const onSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    setError(false)

    const brandDealsType = () => {
      if (data?.useDataDealsType) {
        return data?.dealsType
      } else if (
        pageType == 'powerStation' ||
        pageType == 'charger' ||
        pageType == 'hes'
      ) {
        return pageType.toLowerCase() + '_anker_23_new_subscriber'
      } else {
        return data?.dealsType
      }
    }

    const sub_brand_type_content = () => {
      let text = ''
      switch (pageType) {
        case 'powerStation':
          return (text = 'anker_ankersolix')
        case 'charger':
          return (text = 'anker_charging')
        case 'hes':
          return (text = 'anker_ankersolix')
        default:
          text
      }
    }

    const { register_source } = getAdCookie()
    let url = '/api/multipass/rainbowbridge/activities'
    const result = await fetcher({
      locale,
      url,
      action: 'activity',
      needRecaptcha: true,
      method: 'POST',
      body: {
        email: email.toLowerCase(),
        register_source,
        shopify_domain: I18N_STORE_DOMAIN[`${locale}`],
        single_brand_subscribe: true,
        genre: brandDealsType(),
        sub_brand_type: sub_brand_type_content(),
      },
    })
    setLoading(false)

    console.log('result:', result)

    if (result?.errors) {
      setError(parseMessage(result?.message))
    } else {
      pageGTMEvent({
        subscribe_hashed_email: email ? sha256(email.toLowerCase()) : '',
      })
      pageGTMEvent({
        event: 'ga4Event',
        event_name: 'subscribe',
        event_parameters: {
          page_group: data.dealsType, //如果没有deals_type，且是底部订阅则传footer，其他情况留空
          position: 'pop_up', //传位置标记，比如首屏传masterbanner、弹窗传pop_up、最后一屏传lastbanner、悬浮框传sticky、底部传footer
        },
      })
      pageGTMEvent({
        event: 'uaEvent',
        eventCategory: 'subscribe',
        eventAction: data.dealsType, //如果没有deals_type，且是底部订阅则传footer，其他情况留空
        eventLabel: 'pop_up', //传位置标记，比如首屏传masterbanner、弹窗传pop_up、最后一屏传lastbanner、悬浮框传sticky
      })
      if (result?.data?.coupon?.code) {
        setCode(result?.data?.coupon?.code)
      }
      if (result?.data?.is_new_subscribe || data?.allowRepeatSubscribe) {
        if (result?.data?.coupon?.code) {
          setShowCodeBox(true)
          setShowMemberGift(true)
        } else {
          setError('Sorry, we ran out of codes.')
        }
      } else {
        setError(data.existError)
        if (!result?.data?.dtc_order_assets) setShowMemberGift(true)
      }
    }
  }

  useEffect(() => {
    let resData = {}
    resData.startTime = data?.calendarData?.startTime || ''
    resData.endTime = data?.calendarData?.endTime || ''
    resData.calendarTitle = data?.calendarData?.calendarTitle || ''
    resData.calendarDescribe = data?.calendarData?.calendarDescribe || ''
    resData.location = data?.calendarData?.location || ''
    resData.calendarUrl = data?.calendarData?.calendarUrl || ''
    setCodeData(resData)
  }, [])

  const downloadICSFile = (data) => {
    const icsContent = `BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//My Calendar//EN
BEGIN:VEVENT
UID:${Math.random()}@mycalendar.com
DTSTAMP:${new Date().toISOString().replace(/-|:|\.\d+/g, '')}
DTSTART:${new Date(data?.startTime).toISOString().replace(/-|:|\.\d+/g, '')}
DTEND:${new Date(data?.endTime).toISOString().replace(/-|:|\.\d+/g, '')}
SUMMARY:${data?.calendarTitle} 
DESCRIPTION:${data?.calendarDescribe}
LOCATION:${data?.location}
URL:${data?.calendarUrl}
END:VEVENT
END:VCALENDAR`
    const blob = new Blob([icsContent], { type: 'text/calendar' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'calendar.ics')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(url)
  }

  return (
    <>
      {(open || isVisible) && (
        <PopLayout
          className="relative overflow-hidden rounded-lg text-center font-medium leading-tight md:mx-4 md:w-[358px]"
          theme={data?.theme || 'light'}
        >
          <button
            className="absolute right-4 top-4"
            aria-label="close pop"
            onClick={() => {
              setOpen(false)
              Cookies.set('NewCustomersPop', true)
              if (setIsVisible) {
                setIsVisible(false)
              }
            }}
          >
            <XMarkIcon className="h-[20px] w-[20px] stroke-[#777]" />
          </button>
          <div
            className={cn(
              'max-h-[90vh] w-[516px] overflow-scroll overflow-x-hidden px-16 py-12 md:w-full md:px-5 md:py-12',
              { ['pb-8 md:pb-6']: error },
              data?.theme === 'dark' && 'text-[#fff]'
            )}
          >
            <Text
              html={data.title}
              className="text-[30px] font-semibold md:text-[22px]"
            />
            <Text
              html={data.subTitle}
              className="mt-2 text-[18px] md:text-[16px]"
            />
            <div className="relative">
              {data.bgIcon && (
                <Picture
                  source={data.bgIcon}
                  className="aspect-h-[28] aspect-w-[42] w-full"
                />
              )}
              <div className="absolute inset-0 pt-[70px] text-white md:pt-16">
                <Text
                  html={data.resultTitle}
                  className="text-[14px] font-semibold md:text-[12px]"
                />
                <Text
                  html={data.coupon}
                  className="mt-1.5 text-[36px] font-bold md:text-[24px]"
                />
              </div>
            </div>
            {code && showCodeBox ? (
              <div className="">
                {data?.bgIconSuccess ? (
                  <div className="relative mt-[30px]">
                    <div className="w-[calc(100%+100px)] -translate-x-[43px] md:w-[calc(100%+50px)] md:-translate-x-[26px]">
                      <Picture
                        source={data.bgIconSuccess}
                        className="aspect-h-[667] aspect-w-[950] w-full max-w-none overflow-auto"
                      />
                    </div>
                    <div
                      className={cn(
                        'absolute left-1/2 top-[15%] w-[70%] -translate-x-1/2',
                        data?.theme === 'dark' ? 'text-[#000]' : 'text-[#fff]'
                      )}
                    >
                      <div className="mb-[10px] border-t border-dashed border-[#A2A2A2]"></div>
                      <Text
                        html={data?.couponTitle}
                        className="text-[18px] font-[600] md:text-[16px]"
                      />
                      <div className="mt-[10px] border-t border-dashed border-[#A2A2A2]"></div>
                      <Text
                        html={data?.couponNumber}
                        className="mt-[10px] text-[60px] font-[700] text-[#17BBEF] md:text-[30px]"
                      />
                      <div className="relative">
                        <Text
                          html={data?.couponName}
                          className="text-[18px] font-[500] md:text-[16px]"
                        />
                        {data?.hideCodeLine && (
                          <>
                            <div className="absolute left-0 top-[40%] mb-[10px] w-[25%] border-t border-dashed border-[#A2A2A2]"></div>
                            <div className="absolute right-0 top-[40%] mb-[10px] w-[30%] border-t border-dashed border-[#A2A2A2]"></div>
                          </>
                        )}
                      </div>
                      <div className="mt-[8px] inline-block rounded-full bg-[#fff] pl-[24px] md:pl-[12px]">
                        <Code
                          code={code}
                          data={data}
                          className="flex-row justify-center"
                          s={{
                            code: 'text-[24px] text-[#333] font-semibold mt-1 md:text-[20px]',
                            codePrefix: 'hidden',
                            copy: 'bg-anker-color leading-[1] font-[600] text-[16px] py-[10px] px-[15px] text-white rounded-[30px] ml-4 no-underline md:py-[5px] md:ml-[10px]',
                          }}
                        />
                      </div>
                      <Text
                        html={data?.couponTips}
                        className="mt-[8px] text-[14px] font-[500] md:text-[12px]"
                      />
                    </div>
                    {data?.calendarButton && (
                      <div className="absolute bottom-[-20px] left-1/2 w-full -translate-x-1/2">
                        <Button onClick={() => downloadICSFile(codeData)}>
                          <Picture
                            className="mr-[10px] w-[19px]"
                            source="https://cdn.shopify.com/s/files/1/0508/1815/4652/files/Group_1288112680.svg?v=1717052554"
                          />
                          <span
                            className="mt-[3px]"
                            dangerouslySetInnerHTML={{
                              __html: data?.calendarButton,
                            }}
                          ></span>
                        </Button>
                      </div>
                    )}
                  </div>
                ) : (
                  <Code
                    code={code}
                    data={data}
                    className="flex-row justify-center"
                    s={{
                      code: 'text-[22px] text-[#333] font-semibold mt-1 md:text-[18px]',
                      codePrefix:
                        'text-[22px] text-[#333] font-semibold mt-1 md:text-[18px]',
                      copy: 'bg-anker-color text-[16px] py-2.5 px-10 text-white rounded-[30px] ml-4 no-underline md:px-8',
                    }}
                  />
                )}
                <ul
                  className={cn(
                    'mt-8 grid list-outside list-disc gap-1.5 border-[#eee] pl-4 pt-4 text-left',
                    data?.theme === 'dark' ? 'border-none' : 'border-t'
                  )}
                >
                  {data.notes?.map((note) => (
                    <li
                      key={note}
                      dangerouslySetInnerHTML={{ __html: note }}
                      className="text-[14px] text-[#999]"
                    ></li>
                  ))}
                </ul>
                {data?.memberGift && showMemberGift && (
                  <MemberGift data={data} code={code}></MemberGift>
                )}
              </div>
            ) : (
              <>
                {data?.bgIconDefault && (
                  <div className="my-[30px]">
                    <Picture
                      source={data.bgIconDefault}
                      className="aspect-h-[401] aspect-w-[798] w-full"
                    />
                  </div>
                )}
                <form
                  onSubmit={onSubmit}
                  onInvalid={(e) => {
                    // console.log('e:', e)
                    e.preventDefault()
                    switch (e.target.getAttribute('type')) {
                      case 'email':
                        setError(data.infoErrEmail)
                        break
                      default:
                        setError(data.infoSelectPolicy)
                    }
                  }}
                >
                  <input
                    className={cn(
                      'h-[42px] w-full rounded-[30px] border border-[#DDDDDD] px-5 text-center text-[16px] placeholder:text-center placeholder:text-[16px] placeholder:font-medium',
                      data?.theme === 'dark'
                        ? 'bg-transparent placeholder:text-[#AAAAAA]'
                        : 'bg-white placeholder:text-[#AAAAAA]'
                    )}
                    placeholder={data.placeholder}
                    type="email"
                    required
                    onChange={(e) => {
                      setEmail(e.target.value)
                      setError(false)
                    }}
                  />
                  <Button
                    loading={loading}
                    className="mt-2.5 h-[42px] w-full rounded-[30px] bg-anker-color py-3.5 font-semibold text-white"
                  >
                    {data.btnLabel}
                  </Button>
                  <Policy
                    className={data?.theme === 'dark' && 'text-[#fff]'}
                    text={data.policyText}
                  />
                  <Text
                    html={error}
                    className="mt-2.5 text-center text-[#FF0101]"
                    variant="paragraph"
                  />
                  {data?.memberGift && showMemberGift && (
                    <MemberGift data={data} code={code}></MemberGift>
                  )}
                </form>
              </>
            )}
          </div>
        </PopLayout>
      )}

      <div
        className={cn(
          'fixed bottom-20 left-[-100%] z-30 transition-all duration-300 md:bottom-[-100%] md:left-0 md:right-0 md:mx-6',
          { ['left-[12px] md:bottom-4']: fixedOpen }
        )}
      >
        {data.fixed?.title && (
          <div className="relative grid items-center justify-items-center gap-2 rounded-lg bg-white px-10 py-2.5 shadow md:grid-flow-col md:px-6">
            <Picture
              source={data.fixed?.imgUrl}
              className="h-[75px] w-[68px]"
            />
            <button
              dangerouslySetInnerHTML={{ __html: data.fixed?.title }}
              className="text-center text-sm font-medium md:text-left"
              onClick={() => setOpen(true)}
            />
            <button
              className="absolute right-4 top-4 z-20"
              onClick={() => setFixedOpen(false)}
            >
              <XMarkIcon className="h-4 w-4" />
            </button>
          </div>
        )}
      </div>

      {data?.subscribeIcon && (
        <div
          onClick={() => setOpen(true)}
          className="fixed bottom-20 right-[12px] z-[99] w-[46px] cursor-pointer"
        >
          <Picture
            className="aspect-h-[120] aspect-w-[120] w-full"
            source={data?.subscribeIcon}
          ></Picture>
        </div>
      )}

      {data.topBanner && (
        <div className="layer relative h-[60px] w-full bg-gradient-to-r from-[#5A6176] to-black text-[16px] leading-tight text-white md:text-[12px] x:px-4">
          <div className="content grid !w-fit  grid-flow-col items-center justify-items-center gap-5 md:flex md:flex-col md:justify-center md:gap-0">
            <div className="flex items-center">
              <Picture
                source={data.fixed?.imgUrl}
                className="mt-[-8px] w-[25px] md:mt-0 md:w-[10px]"
              />
              <Text
                html={data.topBanner?.left}
                className="ml-4 font-bold md:ml-1 [&_span]:text-[#F3BC12]"
                variant="paragraph"
              />
            </div>
            <Text
              html={data.topBanner?.center}
              className="[&_p]:text-anker-color"
              variant="paragraph"
            />
            <button
              dangerouslySetInnerHTML={{ __html: data.topBanner?.right }}
              className="mt-1 font-semibold text-anker-color"
              onClick={() => setOpen(true)}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default NewCustomersPop

const MemberGift = ({ code = null, data }) => {
  const { setRelativeLink } = useRelativeLink()
  const link = data?.memberGift?.buttonUrl?.includes('?')
    ? `${setRelativeLink({ link: data?.memberGift?.buttonUrl })}&code=${code}`
    : `${setRelativeLink({ link: data?.memberGift?.buttonUrl })}?code=${code}`
  return (
    <div
      className={
        'mt-[30px] flex items-center gap-[20px] rounded-[20px] bg-[#F6F6F6] px-[24px] py-[12px] md:gap-[10px] md:rounded-[10px] md:px-[18px]'
      }
    >
      <div className="mt-[-30px] w-[35%] md:mt-0">
        <Picture
          source={data?.memberGift?.icon}
          className="aspect-h-[92] aspect-w-[116] w-full"
        />
      </div>
      <div className="flex-1">
        <Text
          html={data?.memberGift?.title}
          className="mb-[10px] text-[16px] font-semibold text-[#333]"
        />
        <Button className="md:h-[36px] md:leading-[36px]">
          <a
            href={link}
            dangerouslySetInnerHTML={{ __html: data?.memberGift?.button }}
          ></a>
        </Button>
      </div>
    </div>
  )
}
